import React from 'react';
import css from './SectionCommunityFavourites.module.css';
import Slider from 'react-slick';
import TrainerCard from '../TrainerCard/TrainerCard';
import { createResourceLocatorString } from '../../util/routes';

import MediaQuery from 'react-responsive';
import { ReactComponent as CustomNextArrow } from '../../assets/rightArrow.svg';
import { ReactComponent as CustomPrevArrow } from '../../assets/leftArrow.svg';
import fav1Img from '../../assets/fav1.jpg';
import fav2Img from '../../assets/fav2.jpg';
import fav3Img from '../../assets/fav3.jpg';
import fav4Img from '../../assets/fav4.jpg';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { FormattedMessage } from 'react-intl';
function SectionCommunityFavourites(props) {
  const { listings, history } = props;
  const routeConfiguration = useRouteConfiguration();
  var settings = {
    dots: false,
    // infinite: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    // arrows:false,
    initialSlide: 0,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //   const trainersCard = new Array(fav1Img, fav2Img, fav3Img, fav4Img);
  const trainersCard = new Array(
    fav1Img,
    fav2Img,
    fav3Img,
    fav4Img,
    fav1Img,
    fav2Img
    // fav3Img,
    // fav4Img
  );
  //   console.log(trainersCard, 258);

  return (
    <div className={css.slickContainer}>
      <div className={css.headerDiv}>
        <span>Our Latest</span>
        <button
          onClick={() => {
            history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {}));
          }}
        >
          Explore All
        </button>
      </div>
      <div className={css.sliderDiv}>
        <p>
        <FormattedMessage id="SectionCommunityFavourites.exploreBlurb" />
        </p>
        {/* {trainersCard.map(l => {
          return <TrainerCard name={l} />;
        })} */}
        <div className={`${css.communityFavourites} communityFavourites`}>
          <Slider {...settings} className={css.communityFavourites}>
            {listings?.map((listing, indx) => {
              //   console.log(258);
              const imageOrVideoLink = listing?.attributes?.publicData?.uploadVedio?.location;
              return <TrainerCard listing={listing} key={indx} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default compose(withRouter)(SectionCommunityFavourites);
