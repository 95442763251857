import React, { useEffect, useMemo, useRef, useState } from 'react';
import css from './TrainerCard.module.css';
import fav1Img from '../../assets/fav1.jpg';
import fav2Img from '../../assets/fav2.jpg';
import fav3Img from '../../assets/fav3.jpg';
import fav4Img from '../../assets/fav4.jpg';
import NamedLink from '../NamedLink/NamedLink';
import { createSlug } from '../../util/urlHelpers';
import { BiVolumeMute } from 'react-icons/bi';
import { GoUnmute } from 'react-icons/go';
import classNames from 'classnames';
import { IconSpinner } from '..';
// import Loader from '../Loader/Loader';
function TrainerCard(props) {
  const { listing } = props;
  const { reviewCount = 0, totalRating = '' } = listing?.attributes?.publicData;
  // const avgRating = parseInt(totalRating, 10) / reviewCount;
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [ismuted, setIsMuted] = useState(true);
  const [isHoverNone, setIsHoverNone] = useState(false);
  const [avgRating, setAvgRating] = useState(null);
  const imageOrVideoLink = listing?.attributes?.publicData?.uploadVedio?.[0]?.location;
  const additionalImageOrVideoLink =
    listing?.attributes?.publicData?.additionalImageOrVedios?.[0]?.location;
  const firstName = listing?.attributes?.publicData?.firstName;
  const lastName = listing?.attributes?.publicData?.lastName;
  const price = listing?.attributes?.price?.amount / 100;
  const ownerTitle = listing?.attributes?.publicData?.personal;
  const id = listing.id.uuid;
  const title = listing?.attributes?.title;
  const slug = createSlug(title);
  const handleHover = () => {
    if (videoRef?.current?.paused) videoRef?.current?.play();
    setIsHoverNone(true);
  };

  const handleMouseLeave = () => {
    videoRef?.current?.pause();
    if (videoRef?.current) videoRef.current.currentTime = 0;
    setIsHoverNone(false);
  };

  const toggleMute = e => {
    e.preventDefault();
    // e.stopPropagation();
    if (videoRef.current.muted) {
      videoRef.current.muted = false;
      setIsMuted(false);
    } else {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  const showRating = useMemo(() => {
    let rating;
    if (typeof totalRating === 'string') rating = parseInt(totalRating, 10) / reviewCount;
    else rating = totalRating / reviewCount;
    return rating;
  }, [totalRating, reviewCount]);
  useEffect(() => {
    setAvgRating(showRating);
  }, [showRating]);

  return (
    <>
      <div className={css.searchLink}>
        <div className={css.imageContainer}>
          {isLoading ? <IconSpinner /> : null}
          {imageOrVideoLink?.includes('/videos/') ? (
            <>
              <video
                // controls
                ref={videoRef}
                // onMouseEnter={handleHover}
                // onMouseLeave={handleMouseLeave}
                className={classNames(css.previewImage, { [css.loaded]: isLoading })}
                onLoadedData={() => setIsLoading(false)}
                muted="muted"
              >
                <source src={imageOrVideoLink} type="video/mp4" />
              </video>
              <div
                className={css.muteButton}
                onClick={toggleMute}
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
              >
                {ismuted ? <BiVolumeMute /> : <GoUnmute />}
              </div>
            </>
          ) : imageOrVideoLink?.includes('/images/') ? (
            <div className={css.imageDiv}>
              <img
                src={imageOrVideoLink}
                className={classNames(css.previewImage, {
                  [css.noneOnHover]: isHoverNone && additionalImageOrVideoLink,
                  [css.loaded]: isLoading,
                })}
                alt="Preview"
                onLoad={() => setIsLoading(false)}
              />
              {additionalImageOrVideoLink?.includes('/videos/') ? (
                <>
                  <video
                    // controls
                    ref={videoRef}
                    // onMouseEnter={handleHover}
                    // onMouseLeave={handleMouseLeave}
                    className={css.previewImage}
                    onLoadedData={() => setIsLoading(false)}
                    muted="muted"
                  >
                    <source src={additionalImageOrVideoLink} type="video/mp4" />
                  </video>
                  <div
                    className={css.muteButton}
                    onClick={toggleMute}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleMouseLeave}
                  >
                    {ismuted ? <BiVolumeMute /> : <GoUnmute />}
                  </div>
                </>
              ) : additionalImageOrVideoLink?.includes('/images/') ? (
                <img src={additionalImageOrVideoLink} className={css.previewImage} alt="Preview" />
              ) : null}
            </div>
          ) : null}
          <NamedLink name="ListingPage" params={{ id, slug }}>
            <div
              className={css.textOverlay}
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
            >
              <div>
                <span>£{price}ph</span>
                {/* <span>4.9*: 925 Reviews</span> */}
                <span>
                  {reviewCount > 0 ? `${avgRating?.toFixed(1)}* : ${reviewCount} Reviews` : null}
                </span>
              </div>
              <div>
                <span>{`${firstName} ${lastName}`}</span>
                <span>{ownerTitle}</span>
              </div>
            </div>
          </NamedLink>
        </div>
      </div>
    </>
    //   <div className={css.cardContainer}>

    //   <div className={css.searchLink}>
    //     <div className={css.imageContainer}>
    //       <img src={fav1Img} alt="name" className={css.searchImage} />
    //       <div className={css.textOverlay}>
    //         <div>
    //           <span>£00ph</span>
    //           <span>4.9*: 925 Reviews</span>
    //         </div>
    //         <div>
    //           <span>Adrienne Adhami</span>
    //           <span>Wellbeing Coach</span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className={css.searchLink}>
    //     <div className={css.imageContainer}>
    //       <img src={fav2Img} alt="name" className={css.searchImage} />

    //       <div className={css.textOverlay}>
    //         <div>
    //           <span>£00ph</span>
    //           <span>4.9*: 925 Reviews</span>
    //         </div>
    //         <div>
    //           <span>Adrienne Adhami</span>
    //           <span>Wellbeing Coach</span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className={css.searchLink}>
    //     <div className={css.imageContainer}>
    //       <img src={fav3Img} alt="name" className={css.searchImage} />

    //       <div className={css.textOverlay}>
    //         <div>
    //           <span>£00ph</span>
    //           <span>4.9*: 925 Reviews</span>
    //         </div>
    //         <div>
    //           <span>Adrienne Adhami</span>
    //           <span>Wellbeing Coach</span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>

    //   <div className={css.searchLink}>
    //     <div className={css.imageContainer}>
    //       <img src={fav4Img} alt="name" className={css.searchImage} />

    //       <div className={css.textOverlay}>
    //         <div>
    //           <span>£00ph</span>
    //           <span>4.9*: 925 Reviews</span>
    //         </div>
    //         <div>
    //           <span>Adrienne Adhami</span>
    //           <span>Wellbeing Coach</span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className={css.searchLink}>
    //     <div className={css.imageContainer}>
    //       <img src={fav3Img} alt="name" className={css.searchImage} />

    //       <div className={css.textOverlay}>
    //         <div>
    //           <span>£00ph</span>
    //           <span>4.9*: 925 Reviews</span>
    //         </div>
    //         <div>
    //           <span>Adrienne Adhami</span>
    //           <span>Wellbeing Coach</span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className={css.searchLink}>
    //     <div className={css.imageContainer}>
    //       <img src={fav3Img} alt="name" className={css.searchImage} />

    //       <div className={css.textOverlay}>
    //         <div>
    //           <span>£00ph</span>
    //           <span>4.9*: 925 Reviews</span>
    //         </div>
    //         <div>
    //           <span>Adrienne Adhami</span>
    //           <span>Wellbeing Coach</span>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default TrainerCard;
