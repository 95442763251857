import React, { useState } from 'react';
import css from './SectionContactUs.module.css';
import ContactUsDeskImgone from '../../assets/contactUsNewDesk.jpg';
import ContactUsDeskImgtwo from '../../assets/contactUsNewDesk1.jpg';
import ContactUsMobImgOne from '../../assets/Coming-Soon-Mobile.jpg';
import ContactUsMobImgtwo from '../../assets/contactUsNewMobImg2.jpg';
import FleggsIconDesktop from '../../assets/fleggsIconDesktop.png';
import FleggsIconMob from '../../assets/fleggsIconMob.png';

import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import ContactUsPage from '../../containers/ContactUsPage/ContactUsPage';
import Modal from '../Modal/Modal';
function SectionContactUs(props) {
  const { history, onManageDisableScrolling } = props;
  const routeConfiguration = useRouteConfiguration();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={css.contactUsdiv}>
        <div className={css.divContent}>
          <div className={css.Img}>
            <img src={ContactUsDeskImgone} alt="name" className={css.deskImage} />
            <img src={ContactUsMobImgOne} alt="name" className={css.mobImage} />
          </div>

          <div className={css.bottomDiv}>
            <h1>Coming Soon: Group Workouts!</h1>
            <p>
              There's nothing better than sharing your fitness journey with friends 💪. We'll be
              introducing group bookings soon, so you can split the bill and work out together.
            </p>
          </div>
        </div>
        <div className={css.divContent}>
          <div className={css.Img}>
            <img src={ContactUsDeskImgtwo} alt="name" className={css.deskImage} />
            <img src={ContactUsMobImgtwo} alt="name" className={css.mobImage} />
            {/* <img src={FleggsIconDesktop} alt="name" className={css.deskImage} />
            <img src={FleggsIconMob} alt="name" className={css.mobImage} /> */}
          </div>

          <div className={css.bottomDiv}>
            <h1>The Fleggs Starter Pack</h1>
            <p>
              We've partnered with our favourite brands to gift you the ultimate girl therapy 💜.
              Start your journey right with the Fleggs Starter Pack, launching soon!
            </p>
            <p>Want to get involved as a partner?</p>
            <button onClick={() => setIsOpen(true)} className={css.contactUsBtn}>
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <Modal
        // containerClassName={css.modalContainer}
        className={css.ContactUsmodal}
        id="contactUsModal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        // showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <ContactUsPage />
      </Modal>
    </>
  );
}

export default compose(withRouter)(SectionContactUs);
